import { createAction, createReducer } from "@reduxjs/toolkit"

import { LOADING_INITIAL_STATE } from "modules/loader-watchers/helpers/loading"
import { routes } from "router/routes"

// ACTIONS
export const setMenuItemActive = createAction("guideUserInfo/setMenuItemActive")

// REDUCER
const initialState = {
  ...LOADING_INITIAL_STATE,
  activeMenu: null,
  menuItems: [
    {
      groupName: "Profile",
      id: "profile",
      items: [
        {
          name: "Profile Information",
          path: routes.proPersonalInfoProfileInfo(),
          id: "profile-information",
          complete: false
        },
        {
          name: "Profile Images",
          path: "",
          id: "profile-images",
          complete: false
        },
        {
          name: "Address",
          path: routes.proPersonalInfoAddressesPath(),
          id: "addresses",
          complete: false
        }
      ]
    },
    {
      groupName: "Professional details",
      id: "professional-details",
      items: [
        {
          name: "Listings",
          path: "",
          id: "listings",
          complete: false
        },
        {
          name: "Licenses & Certifications",
          path: routes.proPersonalInfoLicenses(),
          id: "licenses-and-certifications",
          complete: false
        }
      ]
    },
    {
      groupName: "Payment setup",
      id: "payment_setup",
      items: [
        {
          name: "Connect with Stripe",
          path: "",
          id: "connect-with-stripe",
          complete: false
        }
      ]
    },
    {
      groupName: "Settings and preferences",
      id: "settings_and_preferences",
      items: [
        {
          name: "Mode",
          path: "",
          id: "mode",
          complete: false
        }
      ]
    }
  ]
}

const guideUserInfoReducer = createReducer(initialState, {
  [setMenuItemActive.type]: (state, action) => {
    state.activeMenu = action.payload
  }
})

export default guideUserInfoReducer

import React from "react"
import { Row, Col, Layout } from "antd"
import classNames from "classnames"
import { useTheme } from "context/AntdThemeContext"
import styles from "./AuthenticationLayout.module.css"
import bgMan from "assets/imagesV2/logo_man.svg"
import bgWoman from "assets/imagesV2/logo_woman.svg"
import { themes } from "constants/theme"
import { Link } from "react-router-dom"
import { routes } from "router"
import { getRole } from "utils/app-type"

const { Content } = Layout

// type: signin, signup, ...
export const AuthenticationLayout = ({ type = "signin", children }) => {
  const { theme } = useTheme()
  const logoSrc = theme === themes.sportswoman ? bgWoman : bgMan
  const role = getRole()

  return (
    <Layout>
      <Content>
        <Row className={styles.layout}>
          <Col md={12} sm={0} xs={0} span={12} className={classNames(styles.leftSide, styles[type], styles[theme], styles[role])} />
          <Col span={12} md={12} sm={24} xs={24} className={styles.formWrapper}>
            <div className={styles.formContent}>
              <Link className={styles.logo} to={routes.rootPath()} title="Home">
                <img src={logoSrc} alt="" height="52px" width="auto" />
              </Link>
              {children}
            </div>
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Table } from "react-bootstrap"
import pluralize from "pluralize"
import moment from "moment"

import Logo from "components/common/Logo"

import { useTranslation } from "react-i18next"
import { numberToCurrency } from "helpers/string"

import { useDispatch, useSelector } from "react-redux"
import { modelSelector, collectionSelector } from "store/selectors"
import { getClientBookingIncomes } from "store/bookings"

const BookingForm = ({ disabled = false, className }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const specificBooking = useSelector(modelSelector("booking"))
  const trip = useSelector(modelSelector("trip"))
  const incomes = useSelector(collectionSelector("bookings.incomes"))
  const booking = specificBooking.id || !trip.existing_draft_booking ? specificBooking : trip.existing_draft_booking
  const isSportsman = useSelector((state) => state.user.is_sportsman)

  useEffect(() => {
    if (booking.id) dispatch(getClientBookingIncomes(booking.id))
  }, [booking.id]) //eslint-disable-line

  if (!trip.id) return null

  const classes = ["bg-white rounded p-25 mt-15 vstack flex-fill"]

  return (
    <div className={classes.join(" ")}>
      <div className="text-center">
        {isSportsman ? <Logo alt="Logo" height={32} wide /> : <Logo alt="Logo" height={32} is_sportswoman wide />}
        <h4 className="mt-5">{t("booking.thank_you_for_booking")}</h4>
      </div>
      <div className="my-25">
        <span className="text-uppercase">
          {booking.client.first_name} {booking.client.last_name}
        </span>
        <br />
        <span className="text-uppercase">Date: {moment(booking.booked_date).format("MM/DD/YYYY")}</span>
        <br />
        <span className="">
          {t("booking.receipt.booking_id")}: {booking.uuid}
        </span>
        {booking.receipt_url && (
          <span className="">
            <br />
            <div style={{ textAlign: "center" }}>
              <a
                href={booking.receipt_url}
                target="_blank"
                rel="noreferrer"
                className="hstack bg-primary rounded-pill p-2 ps-3 pe-3"
                style={{
                  color: "white",
                  textDecoration: "none",
                  display: "inline-block",
                  textAlign: "center",
                  fontSize: "12px",
                  marginTop: "10px"
                }}
              >
                {t("booking.receipt.download")}
              </a>
            </div>
          </span>
        )}
      </div>
      <Table hover>
        <thead>
          <tr className="border-bottom">
            <th className="text-dark fw-normal py-3">{booking.trip.title}</th>
            <th className="text-dark fw-normal text-end py-3">{t("booking.payment.labels.amount")}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="text-dark fw-normal py-3">{pluralize("guest", booking.adult_count + booking.child_count, true)}</td>
            <td className="text-dark fw-normal text-end py-3">USD {numberToCurrency(booking.current_amount)}</td>
          </tr>
          <tr>
            <td className="text-dark fw-normal py-3">{`${t("booking.receipt.fees")} ${booking.booking_fee_percentage}%`}</td>
            <td className="text-dark fw-normal text-end py-3">USD {numberToCurrency(booking.booking_fee)}</td>
          </tr>
          <tr className="border-3 border-start-0 border-end-0 border-top-0 border-bottom border-black">
            <td className="text-dark fw-normal py-3"></td>
            <td className="text-dark fw-normal text-end py-3">
              <strong>{t("booking.receipt.subtotal")}: </strong>
              USD {numberToCurrency(booking.current_amount)}
            </td>
          </tr>
          {incomes.map((income) => (
            <tr key={income.id}>
              <td className="text-dark fw-normal py-3">
                <i>{income.payment_type_description}</i>
              </td>
              <td className="text-dark fw-normal text-end py-3">
                <strong>{income.is_refund ? "Refund paid: " : "Paid: "}</strong>
                USD {numberToCurrency(income.amount)}
              </td>
            </tr>
          ))}
          <tr className="border-3 border-start-0 border-end-0 border-bottom-0 border-top border-black">
            <td className="text-dark fw-bold py-3 border-0">
              {t("booking.receipt.due_on")}: {moment(booking.booked_date).format("MM/DD/YYYY")}:
            </td>
            <td className="text-dark fw-normal text-end py-3 border-0">USD {numberToCurrency(booking.outstanding_amount)}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}

BookingForm.propTypes = {
  disabled: PropTypes.bool,
  className: PropTypes.string
}

BookingForm.defaultProps = {
  disabled: false,
  className: ""
}

export default BookingForm

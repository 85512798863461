import React from "react"

import Testimonials from "components/landing/Testimonials"
import Hero from "components/landing/Hero"
import Advantage from "components/landing/Advantage"
import AdvantagesExpanded from "components/landing/AdvantagesExpanded"
import DSBlog from "components/landing/DSBlog"
import Transform from "components/landing/Transform"
import DemoRequest from "components/landing/DemoRequest"

import MetaTags from "react-meta-tags"

import heroImage from "assets/images/landing/Heroicastimagereduced2.png"
import { isProApp } from "utils/app-type"
import { ClientLandingPage } from "./ClientLandingPage"

export default function Landing() {
  if (!isProApp()) {
    return <ClientLandingPage />
  }
  return (
    <>
      <MetaTags>
        <title>Home</title>
        <meta property="og:title" content="The Ultimate Outdoor Experiences at Your Fingertips" />
        <meta property="og:image" content={global.location.origin + heroImage} />
      </MetaTags>
      <Hero />
      <Advantage />
      <AdvantagesExpanded />
      <Testimonials />
      <Transform />
      <DSBlog />
      <DemoRequest />
    </>
  )
}

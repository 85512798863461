import React from "react"
import { Link } from "react-router-dom"
import { Text } from "ui/Typography"
import errorMessage from "./messages"
import { isProApp } from "utils/app-type"

export const CustomInputError = ({ errorKey, customHandler }) => {
  const { text, href, linkText, linkTextGuest } = errorMessage[errorKey] || { text: errorKey }
  const linkTextToDisplay = !isProApp() && linkTextGuest ? linkTextGuest : linkText
  return (
    <Text type="danger">
      {text} {(href && linkText && <Link to={href}>{linkTextToDisplay}</Link>) || (customHandler && customHandler())}
    </Text>
  )
}
